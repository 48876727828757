// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'user'
  }, {
    prop: 'coupon'
  }, {
    prop: 'name'
  }, {
    prop: 'usage'
  }, {
    prop: 'scope'
  }, {
    prop: 'coupon_code'
  }, {
    prop: 'usable_status'
  }, {
    prop: 'discount'
  }, {
    prop: 'target_amount'
  }, {
    prop: 'description'
  }, {
    prop: 'description_1'
  }, {
    prop: 'expire_date'
  }, {
    prop: 'valid_date'
  }, {
    prop: 'ownerable'
  }, {
    prop: 'title'
  }, {
    prop: 'redemption_time'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }, {
    prop: 'deleted_at'
  }]
}
